import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { format, getYear } from 'date-fns'
import moment from 'moment'
import { ptBR } from 'date-fns/locale'
import {
  darkGray,
  largePadding,
  lightGray,
  margin,
  middlePadding,
  normalPadding,
  normalText,
  smallMargin,
  textBold,
  white
} from 'src/Styles/settings/Constants'
import { toMoney } from 'src/Utils/Utils'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import Dots from 'src/UIComponents/Dots/Dots'
import IconButton from 'src/UIComponents/IconButton/IconButton'
import { Menu as ButtonDefaultDropdown } from 'src/UIComponents/ButtonDropdown/ButtonDefaultDropdown'
import { Tooltip } from '../Tooltip'

const CommissionsList = ({
  groupedCommissions,
  handleActionClick,
  handleDeleteExport
}) => {
  const [selected, setSelected] = useState('xlsx')
  const month = (date) => format(date, 'MMM', { locale: ptBR })
  const year = (date) => getYear(date)

  const exportButtonOptions = [
    {
      label: 'Exportar em .XLSX',
      value: 'xlsx'
    },
    {
      label: 'Exportar em .CSV',
      value: 'csv'
    }
  ]

  const handleChange = (commission, optionValue) => {
    setSelected(optionValue)
    handleActionClick(commission, optionValue)
  }

  return (
    <CommissionListUI>
      {groupedCommissions.map((monthGroup, groupIndex) => {
        const { commissions, date } = monthGroup
        const activeGroup = groupIndex === 0

        return (
          <CommissionGroup
            key={`monthCommissions-${groupIndex}`}
            data-test={`markup-month-${month(date)}`}
          >
            <GroupDateWrapper active={activeGroup}>
              <DateText>{month(date)}</DateText>
              <DateText>{year(date)}</DateText>
            </GroupDateWrapper>
            <CommissionList>
              {commissions.map((commission, commissionIndex) => {
                const { commissionDate, amount, id, status } = commission
                const disableExportButton =
                  moment(commissionDate).add(1, 'day') >= moment().endOf('day')

                return (
                  <CommissionItem
                    active={activeGroup}
                    key={`commissionItem-${commissionIndex}`}
                  >
                    <Commission>
                      <TopWrapper>
                        <CommissionDateWrapper>
                          <Span>Markup pago em</Span>
                          <CommissionDate>
                            {format(commissionDate, 'dd/MM/yyyy')}
                          </CommissionDate>
                        </CommissionDateWrapper>
                        <AmountWrapper>
                          <Amount>{toMoney(amount)}</Amount>
                        </AmountWrapper>
                      </TopWrapper>
                      <Line />
                      <BottomWrapper>
                        <IdWrapper>
                          <Span>ID</Span>
                          <LabelCopyToClipboard
                            hoverText
                            text={id}
                            size='smaller'
                            color={activeGroup ? white : darkGray}
                          >
                            {id}
                          </LabelCopyToClipboard>
                        </IdWrapper>
                        <ActionWrapper>
                          {(status === 1 || status === 0) && (
                            <Dots light={activeGroup} />
                          )}
                          {status === 2 && (
                            <>
                              <StyledIconButton
                                whiteBorder={activeGroup}
                                margin='0 5px 0 0'
                                src='/assets/icons/actions/delete.svg'
                                outline
                                onClick={() => handleDeleteExport(commission)}
                                data-test={`markup-btn-delete-${commissionIndex}`}
                                data-dataTracking={`Deletar Relatório de Markup-${commissionIndex}`}
                              />
                              <StyledIconButton
                                whiteBorder={activeGroup}
                                src='/assets/icons/actions/download.svg'
                                outline
                                onClick={() => handleActionClick(commission)}
                                data-test={`markup-btn-download-${commissionIndex}`}
                                data-dataTracking={`Baixar Relatório de Markup-${commissionIndex}`}
                              />
                            </>
                          )}
                          {status === -1 && (
                            <StyledIconButton
                              whiteBorder={activeGroup}
                              src='/assets/icons/actions/reload.svg'
                              outline
                              onClick={() => handleActionClick(commission)}
                              data-test={`markup-btn-download-${commissionIndex}`}
                              data-dataTracking={`Relatório de Markup-${commissionIndex}`}
                            />
                          )}
                          {status === null && (
                            <>
                              <ButtonDefaultDropdown
                                label='Exportar'
                                selected={selected}
                                onChange={(value) =>
                                  handleChange(commission, value)
                                }
                                options={exportButtonOptions}
                                disabled={disableExportButton}
                                data-test='btn-report'
                                data-tracking={`Relatório de markup`}
                                widthAuto
                                outlineColor={activeGroup && white}
                                outline
                                ghost={!activeGroup}
                                data-tip
                                data-for={`export-button-tooltip-${id}`}
                              >
                                <strong>Exportar</strong>
                              </ButtonDefaultDropdown>
                              {disableExportButton && (
                                <Tooltip id={`export-button-tooltip-${id}`}>
                                  <p>
                                    Este relatório estará disponível a partir do
                                    dia{' '}
                                    {moment(commissionDate)
                                      .add(1, 'day')
                                      .format('DD/MM/YYYY')}
                                  </p>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </ActionWrapper>
                      </BottomWrapper>
                    </Commission>
                  </CommissionItem>
                )
              })}
            </CommissionList>
          </CommissionGroup>
        )
      })}
    </CommissionListUI>
  )
}

const CommissionListUI = styled.ul``

const CommissionGroup = styled.li`
  list-style: none;
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: ${margin};
  }
`

const CommissionList = styled.ul`
  width: 85%;
`

const GroupDateWrapper = styled.div`
  width: 15%;
  margin-right: ${smallMargin};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${white};
  border-radius: 1rem;
  padding: ${largePadding} ${middlePadding};
  color: ${darkGray};

  ${({ active, theme: { color } }) =>
    active &&
    css`
      background: ${color};
      color: ${white};
    `}
`

const DateText = styled.span`
  text-transform: capitalize;
  font-size: ${normalText};
  font-weight: ${textBold};
`

const CommissionItem = styled.li`
  list-style: none;
  color: ${darkGray}
  border-radius: 1rem;
  padding: ${middlePadding} ${normalPadding};
  background: ${white};

  ${({ active, theme: { color } }) =>
    active &&
    css`
      background: ${color};
      color: ${white};
    `}

  &:not(:last-of-type) {
    margin-bottom: ${smallMargin};
  }
`

const Commission = styled.div``

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const BottomWrapper = styled(TopWrapper)``

const Line = styled.div`
  min-height: 1px;
  margin: ${smallMargin} 0;
  background: ${lightGray};
`

const CommissionDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const AmountWrapper = styled.div``

const IdWrapper = styled.div``

const ActionWrapper = styled.div``

const Span = styled.span`
  font-weight: bold;
`

const CommissionDate = styled.span`
  font-size: ${normalText};
`

const Amount = styled.span`
  font-size: ${normalText};
  font-weight: ${textBold};
`

const StyledIconButton = styled(IconButton)`
  ${({ whiteBorder }) =>
    whiteBorder &&
    css`
      border: 1px solid ${white};

      svg {
        fill: ${white};
      }
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`

CommissionsList.defaultProps = {
  groupedCommissions: []
}

CommissionsList.propTypes = {
  groupedCommissions: PropTypes.arrayOf(PropTypes.object),
  handleActionClick: PropTypes.func,
  handleDeleteExport: PropTypes.func
}

export default CommissionsList
